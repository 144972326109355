/*
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { combineReducers, Reducer, Action } from "redux";
import userReducer from "./userReducer";
import profileReducer from "./profileReducer";
import vehicleReducer from "./vehicleReducer";
import shopReducer from "./shopReducer";
import communityReducer from "./communityReducer";

const appReducer = combineReducers({
  userReducer,
  profileReducer,
  vehicleReducer,
  shopReducer,
  communityReducer,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer: Reducer = (
  state: RootState | undefined,
  action: Action,
): RootState => {
  return appReducer(state, action);
};

export default rootReducer;
